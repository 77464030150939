import Container from '../components/container'
import { Navlink } from '../components/navlink'

import {
  Box,
  Flex,
  Image,
  Avatar,
  Menu,
  Text,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/core'
import NextLink from 'next/link'
import { useUser } from '../hooks/use-user'
import React, { useState } from 'react'
import { useRouter } from 'next/router'

const Navbar = ({ containerMaxWidth, nomb }) => {
  const { user, signout } = useUser()
  const [openned, setOpenned] = useState(false)
  const { route } = useRouter()

  return (
    <Box mb={['61px', '0', '0', '0']} background='#fff'>
      <Box
        position={['fixed', 'relative', 'relative', 'relative']}
        width={['100%', 'initial', 'initial', 'initial']}
        left={['0', 'initial', 'initial', 'initial']}
        top={['0', 'initial', 'initial', 'initial']}
        zIndex={['1000', 'initial', 'initial', 'initial']}
        background='transparent'
      >
        <Container
          maxW={containerMaxWidth ?? '924px'}
          paddingBottom='0'
          paddingTop={['0.75rem', '0', '0', '0']}
          height={['60px', 'initial', 'initial', 'initial']}
          background={['white', 'transparent', 'transparent', 'transparent']}
          marginBottom={['0']}
          boxShadow={[
            '0px 2px 10px rgba(0, 0, 0, 0.12)',
            'initial',
            'initial',
            'initial'
          ]}
        >
          <Flex justifyContent='space-between' alignItems='center'>
            <NextLink href={user ? '/events' : '/'}>
              <a>
                <Box width='101px' height='34px'>
                  <Image src='/logo.svg' alt='logo' />
                </Box>
              </a>
            </NextLink>
            <Flex
              display={['flex', 'none', 'none', 'none']}
              onClick={() => setOpenned(openned => !openned)}
            >
              <Image
                display={openned ? 'none' : 'block'}
                src='/bigmac.svg'
                alt='open'
              />
              <Image
                display={!openned ? 'none' : 'block'}
                position='relative'
                left='5px'
                top='1px'
                src='/close-bigmac.svg'
                alt='close'
              />
            </Flex>
            <Flex display={['none', 'flex', 'flex', 'flex']}>
              {user && (
                <><Navlink link='/events' text='Booked' isActive={route.includes('events')} />
                  <Navlink link='/dashboard' text='Event types' isActive={route.includes('dashboard')} />
                  <Navlink link='/settings' text='Settings' isActive={route.includes('settings')} />
                  <Navlink link='/blog' text='Blog' isActive={route.includes('blog')} />
                </>
              )}
              {!user && (
                <><Navlink link='/events' text='Booked' isActive={route.includes('events')} />
                  <Navlink link='/dashboard' text='Event types' isActive={route.includes('dashboard')} />
                  <Navlink link='/settings' text='Settings' isActive={route.includes('settings')} />
                  <Navlink link='/blog' text='Blog' isActive={route.includes('blog')} />
                </>
              )}
            </Flex>
            <Flex display={['none', 'flex', 'flex', 'flex']}>
              {user && (
                <Menu>
                  <MenuButton>
                    <Box
                      borderRadius='100%'
                      overflow='hidden'
                      ml='8px'
                    >
                      <Avatar
                        width='43px'
                        height='43px'
                        src={user.avatarUrl}
                        alt='profile'
                      />
                    </Box>
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={signout}>Sign Out</MenuItem>
                  </MenuList>
                </Menu>
              )}
              {!user && <Navlink link='/sign-in' signup text='Sign In' />}
            </Flex>
          </Flex>
        </Container>
        <Box
          display={['block', 'none', 'none', 'none']}
          position='absolute'
          width='100%'
          height={openned ? 'calc(100vh - 60px)' : '0'}
          background='rgba(0, 0, 0, 0.3)'
          transition='ease-in-out 0.2s'
          opacity='0'
          transform='translate3d(-150%, 0, 0)'
          className={openned ? 'menu-openned' : ''}
        >
          <Flex
            direction='column'
            background='#fff'
            padding='10px 20px 20px 20px'
          >
            <Box width='100%'>
              {user && (
                <Box mt='14px'>
                  <Flex alignItems='center' mb='8px'>
                    <Box
                      borderRadius='100%'
                      overflow='hidden'
                      mr='12px'
                    >
                      <Avatar
                        width='43px'
                        height='43px'
                        src={user.avatarUrl}
                        alt='profile'
                      />
                    </Box>
                    <Text fontSize='1.5625rem' fontWeight='700'>
                      You
                    </Text>
                  </Flex>
                  <Navlink link='/events' text='Booked events' isMobile />
                  <Navlink link='/dashboard' text='Event types' isMobile />
                  <Navlink link='/settings' text='Settings' isMobile />

                  <Navlink onClick={signout} link='' text='Sign Out' isMobile />
                  <Box
                    width='100%'
                    height='1px'
                    background='#E5E9EF'
                    mt='8px'
                    mb='8px'
                  />
                </Box>
              )}
              {!user && <Navlink link='/sign-in' isMobile text='Sign In' />}
              <Navlink link='/blog' text='Blog' isMobile />
              <Navlink link='/coming' text='Roadmap' isMobile />
            </Box>
          </Flex>
        </Box>
        <style>
          {`
          .menu-openned {
            transform: translate3d(0, 0, 0);
            opacity: 1;
            transition: ease-in-out 0.2s;
          }
        `}
        </style>
      </Box>
    </Box>
  )
}

export default React.memo(Navbar)
