import { useEffect } from 'react'
import Router from 'next/router'
import useSWR from 'swr'
import axios from 'axios'
import * as Sentry from '@sentry/node'

const fetcher = url => axios(url).then(r => r.data)

const useUser = ({ redirectTo, redirectIfFound } = {}) => {
  const { data: user, error, mutate: mutateUser } = useSWR('/api/user/me', fetcher)
  const hasUser = Boolean(user)

  async function signout () {
    try {
      await axios.post('/api/auth/sign-out')
      window.location.href = '/'
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    if (error?.response?.status === 401 && redirectTo) {
      Router.push(redirectTo)
    }

    if (!user) return

    if (redirectTo && !hasUser) {
      Router.push(redirectTo)
    }

    if (redirectIfFound && hasUser) {
      Router.push(redirectIfFound)
    }
  }, [redirectTo, redirectIfFound, hasUser, error])

  return { error, user, signout, mutateUser }
}

export { useUser }
