import React from 'react'
import { Box } from '@chakra-ui/core'

export const Container = (props) => (
  <Box w='full' pb='12' pt='3' mx='auto' maxW='1024px' px={6} paddingLeft='20px' paddingRight='20px' {...props}>
    {props.children}
  </Box>
)

export default Container
