import { Text, Link as ChakraLink, Flex } from '@chakra-ui/core'
import NextLink from 'next/link'
import axios from 'axios'
import useSWR from 'swr'

const Navlink = ({ text, link, signup, isMobile, onClick, isActive }) => {
  const { data: events } = text === 'Booked'
    ? useSWR(
        ['/api/dashboard/events/my/upcoming?limit=12&skip=0'],
        (_) =>
          axios.get(_).then(res => res.data)
      ) : { data: { events: [] } }

  return (
    <NextLink href={link}>
      <ChakraLink
        display={isMobile ? 'block' : 'flex'}
        alignItems='center'
        marginLeft={isMobile ? '0' : '14px'}
        paddingTop='9px'
        paddingBottom='9px'
        paddingLeft={signup ? '16px' : '0px'}
        paddingRight={signup ? '16px' : '0px'}
        fontSize={isMobile ? '1.25rem' : 'initial'}
        border={signup ? '1px solid transparent' : '1px solid transparent'}
        marginRight={isMobile ? '0' : '14px'}
        onClick={onClick}
        height='68px'
        borderRadius='0'
        position='relative'
        borderBottom={isActive ? '2px solid #000' : '2px solid transparent'}
      >
        <Text
          fontWeight={signup ? 700 : 700}
          opacity={signup || isMobile ? 1 : 0.5}
          color={signup || isMobile ? '#000' : '#1A202C'}
        >
          {text}
        </Text>
        {
          text === 'Booked' && events && events.length > 0 && <Flex
            width='15px' height='15px'
            justifyContent='center' alignItems='center'
            position='absolute'
            right='-18px' top='14px'
            background='#4065ff' borderRadius='100%'
                                                              >
            <Text fontSize='0.65rem' color='#fff' fontWeight='600'>{events && events.length}</Text>
                                                              </Flex>
        }

      </ChakraLink>
    </NextLink>
  )
}

export { Navlink }
